import { Select } from "antd";
import { Console } from "console";
import { Game } from "model/ecosystem";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectGame, selectEnv } from "redux/actions/Game";
import gameService from "services/EcosystemService";

type Props = {
  selectedGame: Game;
  selectedEnv: string;
  user: firebase.default.User;
  selectGame: (g: Game) => void;
  selectEnv: (env: string) => void;
};

const GameSelector = (props: Props) => {
  const { selectedGame, selectGame } = props;
  const [games, setGames] = useState<Game[]>([]);
  const { selectedEnv, selectEnv } = props;

  useEffect(() => {
    if (!selectedGame) {
      if (games.length > 0) {
        selectGame(games[0]);
      }
    }
  }, [games, selectedGame, selectGame]);

  useEffect(() => {
    gameService.getGames().then(setGames);
  }, [selectGame]);

  const fetchAndSelectGame = (gameId: string) => {
    const gameToSelect = games.find((elm) => elm.id === gameId);
    if (gameToSelect) {
      selectGame(gameToSelect);
    }
  };

  const handleEnvChange = (env: string) => {
    selectEnv(env);
  };

  if (games.length === 0 || !selectedGame) return null;

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Select defaultValue={selectedGame.id} onChange={fetchAndSelectGame}>
        {games.map((game) => (
          <Select.Option key={game.id} value={game.id}>
            {game.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={selectedEnv}
        onChange={handleEnvChange}
        style={{ width: 200 }}
        disabled={selectedGame?.env?.length > 1 ? false : true}
      >
        {selectedGame?.env?.map((env) => (
          <Select.Option key={env} value={env}>
            {env}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

const mapStateToProps = ({ game }: any) => {
  const { selectedGame, selectedEnv } = game;
  return { selectedGame, selectedEnv };
};

const mapDispatchToProps = {
  selectGame,
  selectEnv,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameSelector);
