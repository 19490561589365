import { Game } from "model/ecosystem";
import { SELECT_GAME, UNSELECT_GAME, SELECT_ENV } from "redux/constants/Game";

export const selectGame = (game: Game) => {
  return {
    type: SELECT_GAME,
    game,
  };
};

export const unselectGame = () => {
  return {
    type: UNSELECT_GAME,
  };
};

export const selectEnv = (env: string) => {
  return {
    type: SELECT_ENV,
    env,
  };
};
