import { APP_PREFIX_PATH, GAME_SELECTION_PREFIX_PATH } from "configs/AppConfig";
import { Game } from "model/ecosystem";
import { Action } from "redux";
import { select } from "redux-saga/effects";
import { SELECT_GAME, UNSELECT_GAME, SELECT_ENV } from "redux/constants/Game";

type GameState = {
  selectedGame?: Game;
  selectedEnv?: string;
  redirect: string;
};

const initState: GameState = {
  selectedGame: undefined,
  selectedEnv: undefined,
  redirect: "",
};

const game = (state = initState, action: any) => {
  switch (action.type) {
    case SELECT_GAME:
      return {
        ...state,
        selectedGame: action.game,
        selectedEnv: action.game.env[0],
        redirect: APP_PREFIX_PATH,
      };
    case UNSELECT_GAME:
      return {
        ...state,
        selectedGame: undefined,
        selectedEnv: undefined,
        redirect: GAME_SELECTION_PREFIX_PATH,
      };
    case SELECT_ENV:
      return {
        ...state,
        selectedEnv: action.env,
      };
    default:
      return state;
  }
};

export default game;
